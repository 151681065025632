export const dvsResponses = [
    {
      errorType: "Card number not matched",
      documentType: "DRIVERS_LICENCE",
      attempts: [
        {
          title: "We couldn't verify your card number",
          content: "Check you've entered the card number exactly as it appears on your drivers licence and try again. The card number and licence number are different. To find your card number, refer to the image.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your card number",
          content: "Check you've entered the card number exactly as it appears on your drivers licence and try again. The card number and licence number are different. To find your card number, refer to the image.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Licence number does not match",
      documentType: "DRIVERS_LICENCE",
      attempts: [
        {
          title: "We couldn't verify your licence number",
          content: "Check you've entered the licence number exactly as it appears on your drivers licence and try again. You can find your licence number on the front of your licence.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your licence number",
          content: "Check you've entered the licence number exactly as it appears on your drivers licence and try again. You can find your licence number on the front of your licence.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Date of Birth does not match",
      documentType: "DRIVERS_LICENCE",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "Check the personal details you entered match your ID document.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "Check the personal details you entered match your ID document.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "First name does not match",
      documentType: "DRIVERS_LICENCE",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "Check the personal details you entered match your ID document.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "Check the personal details you entered match your ID document.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Family name does not match",
      documentType: "DRIVERS_LICENCE",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "Check the personal details you entered match your ID document.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "Check the personal details you entered match your ID document.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Document Temporarily Locked",
      documentType: "DRIVERS_LICENCE",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "There's an issue with this ID document. Contact your document issuer for help.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "There's an issue with this ID document. Contact your document issuer for help.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Document Invalid",
      documentType: "DRIVERS_LICENCE",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "There's an issue with this ID document. Contact your document issuer for help.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "There's an issue with this ID document. Contact your document issuer for help.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Document number does not match",
      documentType: "AUSTRALIAN_PASSPORT",
      attempts: [
        {
          title: "We couldn't verify your passport number",
          content: "Check your passport number and try again.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your passport number",
          content: "Check your passport number and try again.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "First name does not match",
      documentType: "AUSTRALIAN_PASSPORT",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "Check the personal details you entered match your ID document. Include your middle name if you have one.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "Check the personal details you entered match your ID document. Include your middle name if you have one.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Date of birth does not match",
      documentType: "AUSTRALIAN_PASSPORT",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "Check the personal details you entered match your ID document. Include your middle name if you have one.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "Check the personal details you entered match your ID document. Include your middle name if you have one.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Family name does not match",
      documentType: "AUSTRALIAN_PASSPORT",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "Check the personal details you entered match your ID document. Include your middle name if you have one.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "Check the personal details you entered match your ID document. Include your middle name if you have one.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Document Invalid",
      documentType: "AUSTRALIAN_PASSPORT",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "There's an issue with this ID document. Contact your document issuer for help.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "There's an issue with this ID document. Contact your document issuer for help.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Document temporarily locked",
      documentType: "AUSTRALIAN_PASSPORT",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "There's an issue with this ID document. Contact your document issuer for help.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "There's an issue with this ID document. Contact your document issuer for help.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "First Name must contain alpha characters, apostrophes, hyphens and spaces only",
      documentType: "AUSTRALIAN_PASSPORT",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "First name must contain alpha characters, apostrophes, hyphens and spaces only.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "First name must contain alpha characters, apostrophes, hyphens and spaces only.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Travel document could not be found",
      documentType: "INTERNATIONAL_PASSPORT",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "There's an issue with this ID document. Contact your document issuer for help.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "There's an issue with this ID document. Contact your document issuer for help.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Both the First and Family Name do not match",
      documentType: "INTERNATIONAL_PASSPORT",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "Check the personal details you entered match your ID document. Include your middle name if you have one.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "Check the personal details you entered match your ID document. Include your middle name if you have one.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Date of birth does not match",
      documentType: "INTERNATIONAL_PASSPORT",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "Check the personal details you entered match your ID document. Include your middle name if you have one.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "Check the personal details you entered match your ID document. Include your middle name if you have one.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Document temporarily locked",
      documentType: "INTERNATIONAL_PASSPORT",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "There's an issue with this ID document. Contact your document issuer for help.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "There's an issue with this ID document. Contact your document issuer for help.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Name does not match",
      documentType: "MEDICARE",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "Check the personal details you entered match your ID document. Include your full middle name or initial exactly as it appears on your Medicare card. Also, make sure to enter your correct individual reference number (IRN).",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "Check the personal details you entered match your ID document. Include your full middle name or initial exactly as it appears on your Medicare card. Also, make sure to enter your correct individual reference number (IRN).",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Date of birth does not match",
      documentType: "MEDICARE",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "Check the personal details you entered match your ID document. Include your full middle name or initial exactly as it appears on your Medicare card.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "Check the personal details you entered match your ID document. Include your full middle name or initial exactly as it appears on your Medicare card.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Expiry Date does not match",
      documentType: "MEDICARE",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "Check the 'Valid to' date on your Medicare card and try again.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "Check the 'Valid to' date on your Medicare card and try again.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Card Number does not match",
      documentType: "MEDICARE",
      attempts: [
        {
          title: "We couldn't verify your Medicare number",
          content: "Check your Medicare card number and try again. Don't include any spaces.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your Medicare number",
          content: "Check your Medicare card number and try again. Don't include any spaces.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Document temporarily locked",
      documentType: "MEDICARE",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "There's an issue with this ID document. Contact your document issuer for help.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "There's an issue with this ID document. Contact your document issuer for help.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Card Type does not match",
      documentType: "MEDICARE",
      attempts: [
        {
          title: "We couldn't verify your Medicare card type",
          content: "Check the card colour and type on your Medicare card and try again. A standard Medicare card is green, an interim card is blue and a reciprocal card is yellow.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your Medicare card type",
          content: "Check the card colour and type on your Medicare card and try again. A standard Medicare card is green, an interim card is blue and a reciprocal card is yellow.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Document invalid",
      documentType: "MEDICARE",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "There's an issue with this ID document. Contact your document issuer for help.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "There's an issue with this ID document. Contact your document issuer for help.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Document invalid (lower case 'I')",
      documentType: "MEDICARE",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "There's an issue with this ID document. Contact your document issuer for help.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "There's an issue with this ID document. Contact your document issuer for help.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Travel document could not be found",
      documentType: "IMMI_CARD",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "There's an issue with this ID document. Contact your document issuer for help.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "There's an issue with this ID document. Contact your document issuer for help.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Both the First and Family Name do not match",
      documentType: "IMMI_CARD",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "Check the personal details you entered match your ID document. Include your middle name if you have one.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "Check the personal details you entered match your ID document. Include your middle name if you have one.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Date of birth does not match",
      documentType: "IMMI_CARD",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "Check the personal details you entered match your ID document. Include your middle name if you have one.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "Check the personal details you entered match your ID document. Include your middle name if you have one.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Document temporarily locked",
      documentType: "IMMI_CARD",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "There's an issue with this ID document. Contact your document issuer for help.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "There's an issue with this ID document. Contact your document issuer for help.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Document not found",
      documentType: "",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "There's an issue with this ID document. Contact your document issuer for help.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "There's an issue with this ID document. Contact your document issuer for help.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "First Name must not exceed 31 characters",
      documentType: "",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "Invalid entry. The first name must be under 31 characters.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "Invalid entry. The first name must be under 31 characters.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Document is invalid or not electronically captured",
      documentType: "",
      attempts: [
        {
          title: "Unable to Verify Your Provided ID",
          content: "We encountered an issue verifying your ID. Please try using a different ID type.",
          count: "You have 1 attempt left"
        },
        {
          title: "Unable to Verify Your Provided ID",
          content: "We encountered an issue verifying your ID. Please try using a different ID type.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "Document is invalid",
      documentType: "",
      attempts: [
        {
          title: "Unable to Verify Your Provided ID",
          content: "We encountered an issue verifying your ID. Please try using a different ID type.",
          count: "You have 1 attempt left"
        },
        {
          title: "Unable to Verify Your Provided ID",
          content: "We encountered an issue verifying your ID. Please try using a different ID type.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "System availability error",
      documentType: "",
      attempts: [
        {
          title: "Unable to Verify Your Provided ID",
          content: "We encountered an issue verifying your ID. Please try using a different ID type.",
          count: "You have 1 attempt left"
        },
        {
          title: "Unable to Verify Your Provided ID",
          content: "We encountered an issue verifying your ID. Please try using a different ID type.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "System error",
      documentType: "",
      attempts: [
        {
          title: "Unable to Verify Your Provided ID",
          content: "We encountered an issue verifying your ID. Please try using a different ID type.",
          count: "You have 1 attempt left"
        },
        {
          title: "Unable to Verify Your Provided ID",
          content: "We encountered an issue verifying your ID. Please try using a different ID type.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
    {
      errorType: "default",
      documentType: "",
      attempts: [
        {
          title: "We couldn't verify your ID",
          content: "There was an issue verifying your ID.  Please try again.",
          count: "You have 1 attempt left"
        },
        {
          title: "We couldn't verify your ID",
          content: "There was an issue verifying your ID.  Please try again.",
          count: "You have 2 of 3 attempts left"
        }
      ]
    },
  ];